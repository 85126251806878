const nunitoBold = require("./Quicksand-Bold.ttf")
const nunitoLight = require("./AcidGrotesk-Light.otf")
const nunitoRegular = require("./AcidGrotesk-Regular.otf")
const nunitoSemiBold = require("./Quicksand-SemiBold.ttf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
